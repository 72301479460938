<template>
    <div style="display: flex; width: 100%; height: 100%; flex-direction: column; overflow: hidden;">
        <vue-html2pdf
            :show-layout="false"
            :filename="Invoice__invno"
            :float-layout="true"
            :enable-download="false"
            :html-to-pdf-options="htmlToPdfOptions"
            :paginate-elements-by-height="1120"
            :manual-pagination="true"
            :pdf-quality="2"
            :preview-modal="false"
            pdf-format="a4"
            pdf-content-width="750px"
            ref="html2Pdf"
            @beforeDownload="beforeDownload($event)"
        >
            <section slot="pdf-content">
                <v-app>
                    <div class="d-flex flex-column fill-height paper-a4">
                        <v-col class="pa-0 ma-0 mb-auto">
                            <v-row no-gutters>
                                <v-col class="col-9-5 d-flex flex-column pt-3">
                                    <div>
                                        <span class="header-title">{{ Office__title  }}</span>
                                        <span class="header-subtitle font-weight-bold" style="vertical-align: top" v-if="Office__companyno != ''">{{ '(' + Office__companyno + ')' }}</span>
                                    </div>
                                    <span class="header-subtitle pt-1 pb-0">{{ Office__address1  }}</span>
                                    <span class="header-subtitle pt-1 pb-0">{{ [Office__address2, Office__postcode, Office__city, Office__state, Office__country].filter(word => word != null).join(', ')  }}</span>
                                    <div class="d-flex flex-row">
                                        <span class="header-subtitle pt-1 pb-0" v-if="Office__contracttel != null && Office__contracttel != ''">{{ $t('message.tel') + ': ' + Office__contracttel  }}</span>
                                        <span :class="'header-subtitle pt-1 pb-0' + (Office__contracttel != null && Office__contracttel != '' ? ' ml-10' : '')" v-if="Contract__office_id == 20">{{ getOfficeTaxId() }}</span>
                                    </div>
                                    <span class="header-subtitle pt-1 pb-0">{{ $t('message.email') + ': ' + Office__invoiceemail + ' ' + $t('message.website') + ': ' + Office__website }}</span>
                                </v-col>
                                <v-col class="col-2-5 text-right">
                                    <img :src="appLogo" class="mb-3 mr-3" width="130px"/>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" class="text-center text-uppercase border-top-2 border-bottom-2 pa-0 font-weight-bold document-caption">{{ documentTitle }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4">
                                    <v-row no-gutters>
                                        <v-col cols="3" class="font-weight-bold">{{ $t('message.buyer') }}:</v-col>
                                        <v-col cols="8" class="d-flex flex-column">
                                            <span>{{ Customer__title }}</span>
                                            <span>{{ Customer__address1 }}</span>
                                            <span>{{ Customer__address2 }}</span>
                                            <span>{{ Customer__address3 }}</span>
                                            <span>{{ Customer__city + ' ' + (Customer__postcode != null ? Customer__postcode : '') }}</span>
                                            <span>{{ Customer__state }}</span>
                                            <span>{{ Customer__country }}</span>
                                            <span>{{ getTaxId() }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="3" class="font-weight-bold">{{ $t('message.attn') }}:</v-col>
                                        <v-col cols="8">{{ Contact__prefix ? Contact__prefix + ' ' : '' }}{{ Contact__name ? Contact__name : $t('message.na').toUpperCase() }}</v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="3" class="font-weight-bold">{{ $t('message.tel') }}:</v-col>
                                        <v-col cols="8">{{ Contact__tel ? Contact__tel : $t('message.na').toUpperCase() }}</v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="3" class="font-weight-bold">{{ $t('message.hp') }}:</v-col>
                                        <v-col cols="8">{{ Contact__hp ? Contact__hp : $t('message.na').toUpperCase() }}</v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="3" class="font-weight-bold">{{ $t('message.email') }}:</v-col>
                                        <v-col cols="8">{{ Contact__email ? Contact__email : $t('message.na').toUpperCase() }}</v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="col-4-5">
                                    <v-row no-gutters>
                                        <v-col cols="5" class="font-weight-bold">{{ $t('message.paymentTerms') }}:</v-col>
                                        <v-col cols="7">{{ Paymentstatement__title }}</v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="5" class="font-weight-bold">{{ $t('message.deliveryTerms') }}:</v-col>
                                        <v-col cols="7">{{ contractIncoterm }}</v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="col-3-5">
                                    <v-row no-gutters>
                                        <v-col cols="6" class="font-weight-bold pl-4">{{ $t('message.date') }}:</v-col>
                                        <v-col cols="6">{{ Invoice__invdate ? formatDate(Invoice__invdate) : '' }}</v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="6" class="font-weight-bold pl-4">{{ $t('message.invoiceNo') }}:</v-col>
                                        <v-col cols="6">{{ Invoice__invno }}</v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="6" class="font-weight-bold pl-4">{{ $t('message.contractNo') }}:</v-col>
                                        <v-col cols="6">{{ Contract__version != null ? [Contract__title, Contract__revision_no , Contract__partial_no].filter(Boolean).join('-') : Contract__title }}</v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="6" class="font-weight-bold pl-4">{{ $t('message.marketing') }}:</v-col>
                                        <v-col cols="6">{{ Salescontact__name }}</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-simple-table class="mt-4 specification-table" dense>
                                <template v-slot:default>
                                    <tr class="table-header">
                                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 width-10-pct">{{ $t('message.species') }}</td>
                                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 width-18-pct">{{ $t('message.description') }}</td>
                                        <td class="pl-3 pr-0 font-weight-bold border-bottom-1 border-top-1 width-10-pct">{{ $t('message.grade') }}</td>
                                        <td class="px-0 font-weight-bold border-bottom-1 border-top-1 width-12-pct">{{ $t('message.size') }}</td>
                                        <td class="px-0 font-weight-bold text-right width-12-pct border-bottom-1 border-top-1">{{ $t('message.quantity') }}</td>
                                        <template v-if="Contract__salestype_id == 5 && Contract__currency_id != Invoice__currency_id && Contract__office_id != 20">
                                            <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1">
                                                <div class="d-flex flex-column">
                                                    <span>{{ $t('message.unitPrice') }}</span>
                                                    <span>{{ getCurrencyCode(Contract__currency_id) }}</span>
                                                </div>
                                            </td>
                                            <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1">
                                                <div class="d-flex flex-column">
                                                    <span>{{ $t('message.total') }}</span>
                                                    <span>{{ getCurrencyCode(Contract__currency_id) }}</span>
                                                </div>
                                            </td>
                                        </template>
                                        <template v-if="Contract__salestype_id == 5 && dualCurrency && Contract__office_id == 20">
                                            <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1">
                                                <div class="d-flex flex-column">
                                                    <span>{{ $t('message.unitPrice') }}</span>
                                                    <span>{{ getCurrencyCode(Contract__currency_id) }}</span>
                                                </div>
                                            </td>
                                            <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1">
                                                <div class="d-flex flex-column">
                                                    <span>{{ $t('message.total') }}</span>
                                                    <span>{{ getCurrencyCode(Contract__currency_id) }}</span>
                                                </div>
                                            </td>
                                        </template>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1" v-if="Invoice__show_alternate_currency">
                                            <div class="d-flex flex-column">
                                                <span>{{ $t('message.price') }}</span>
                                                <span>{{ getCurrencyCode(Invoice__currency_id) }}</span>
                                            </div>
                                        </td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1" v-if="Invoice__show_alternate_currency">
                                            <div class="d-flex flex-column">
                                                <span>{{ $t('message.total') }}</span>
                                                <span>{{ getCurrencyCode(Invoice__currency_id) }}</span>
                                            </div>
                                        </td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1" v-if="![5].includes(Contract__salestype_id)">
                                            <div class="d-flex flex-column">
                                                <span>{{ $t('message.price') }}</span>
                                                <span>{{ getCurrencyCode(Invoice__currency_id) }}</span>
                                            </div>
                                        </td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1" v-if="![5].includes(Contract__salestype_id)">
                                            <div class="d-flex flex-column">
                                                <span>{{ $t('message.total') }}</span>
                                                <span>{{ getCurrencyCode(Invoice__currency_id) }}</span>
                                            </div>
                                        </td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1" v-if="isTaxInvoice">
                                            <div class="d-flex flex-column">
                                                <span>{{ $t('message.price') }}</span>
                                                <span>{{  getTaxCurrency(Office__currency_id) }}</span>
                                            </div>
                                        </td>
                                        <td class="px-0 font-weight-bold text-right width-10-pct border-bottom-1 border-top-1" v-if="isTaxInvoice">
                                            <div class="d-flex flex-column">
                                                <span>{{ $t('message.total') }}</span>
                                                <span>{{ getTaxCurrency(Office__currency_id) }}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <template v-for="(item,index) in Salesitems" v-if="item.type == 'default'">
                                        <tr class="table-row-main" v-if="index == 0 ">
                                            <td :colspan="(Contract__salestype_id == 5 && Contract__currency_id != Invoice__currency_id && Contract__office_id != 20) || (Contract__salestype_id == 5 && dualCurrency && Contract__office_id == 20) || isTaxInvoice ? 9 : 6" v-if="useLcDescriptions">{{ item.Salesdescription.lcdescription }}</td>
                                            <td :colspan="(Contract__salestype_id == 5 && Contract__currency_id != Invoice__currency_id && Contract__office_id != 20) || (Contract__salestype_id == 5 && dualCurrency && Contract__office_id == 20) || isTaxInvoice ? 9 : 6" v-else>{{ item.Species.title }}</td>
                                        </tr>
                                        <tr class="table-row" v-else-if="index > 0 && Salesitems[index - 1].Salesdescription.title != item.Salesdescription.title">
                                            <td :colspan="(Contract__salestype_id == 5 && Contract__currency_id != Invoice__currency_id && Contract__office_id != 20) || (Contract__salestype_id == 5 && dualCurrency && Contract__office_id == 20) || isTaxInvoice ? 9 : 6" v-if="useLcDescriptions">{{ item.Salesdescription.lcdescription }}</td>
                                            <td :colspan="(Contract__salestype_id == 5 && Contract__currency_id != Invoice__currency_id && Contract__office_id != 20) || (Contract__salestype_id == 5 && dualCurrency && Contract__office_id == 20) || isTaxInvoice ? 9 : 6" v-else>{{ item.Species.title }}</td>
                                        </tr>
                                        <tr class="table-row-sub">
                                            <td></td>
                                            <td class="px-0">{{ (useLcDescriptions == false ? getProduct(item.Productgroup.title, item.Salesitem.description, item.Spec.title) + (item.Salesitem.description ? item.Salesitem.description : item.Spec.title) : '') + getCertificationLabel(item.Salesdescription.title,item.Salesitem.certification_id) }}</td>
                                            <td class="px-0">{{ item.Grade.title }}</td>
                                            <td class="px-0">{{ item.Size.title }}</td>
                                            <td class="px-0 text-right text-no-wrap">
                                                <template v-if="Contract__salestype_id == 5 && Contract__office_id == 20">
                                                    {{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invQtyInCbf, '0,0.000') + ' CBF' : '' }}
                                                </template>
                                                <template v-else>
                                                    {{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invqty,uofmPrecision(item.Measurement.title)) + ' ' + getUofmQty(item.Salesitem.measurement_id) : '' }}
                                                </template>
                                            </td>
                                            <template v-if="Contract__salestype_id == 5 && Contract__office_id == 20 && dualCurrency">
                                                <td class="px-0 text-right">
                                                    {{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invPriceInCbf,'0,0.00') : '' }}
                                                </td>
                                            </template>
                                            <template v-if="Contract__salestype_id != 5 && Contract__office_id != 20">
                                                <td class="px-0 text-right">
                                                    {{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invprice,[5].includes(Contract__salestype_id) ? '0,0.00' : '0,0.00') : '' }}
                                                </td>
                                            </template>
                                            <template v-if="Contract__salestype_id == 5 && Contract__office_id != 20">
                                                <td class="px-0 text-right">
                                                    {{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invprice,[5].includes(Contract__salestype_id) ? '0,0.00' : '0,0.00') : '' }}
                                                </td>
                                            </template>
                                            <template v-if="Contract__salestype_id != 5 && Contract__office_id != 20">
                                                <td class="px-0 text-right">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invoiceamount,[5].includes(Contract__salestype_id) ? contractCurrencyFormatPrecision : currencyFormatPrecision) : '(' + formatThisNumber(item.Salesitem.invoiceamount,currencyFormatPrecision) + ')' }}</td>
                                            </template>
                                            <template v-if="Contract__salestype_id == 5 && Contract__office_id != 20">
                                                <td class="px-0 text-right">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invoiceamount,[5].includes(Contract__salestype_id) ? contractCurrencyFormatPrecision : currencyFormatPrecision) : '(' + formatThisNumber(item.Salesitem.invoiceamount,currencyFormatPrecision) + ')' }}</td>
                                            </template>
                                            <template v-if="Contract__salestype_id == 5 && Contract__office_id == 20 && dualCurrency">
                                                <td class="px-0 text-right">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invoiceamount,[5].includes(Contract__salestype_id) ? contractCurrencyFormatPrecision : currencyFormatPrecision) : '(' + formatThisNumber(item.Salesitem.invoiceamount,currencyFormatPrecision) + ')' }}</td>
                                            </template>
                                            <td class="px-0 text-right" v-if="Invoice__show_alternate_currency && [5].includes(Contract__salestype_id) && Contract__currency_id != Invoice__currency_id">
                                                <template v-if="Contract__office_id == 20">
                                                    {{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invPriceConvertedInCbf, '0,0.00') : '' }}
                                                </template>
                                                <template v-else>
                                                    {{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invprice_converted,currencyFormatPrecision) : '' }}
                                                </template>
                                            </td>
                                            <td class="px-0 text-right width-15-pct" v-if="Invoice__show_alternate_currency && [5].includes(Contract__salestype_id) && Contract__currency_id != Invoice__currency_id"><span class="ml-2">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invoiceamount_converted,currencyFormatPrecision) : '(' + formatThisNumber(item.Salesitem.invoiceamount_converted,currencyFormatPrecision) + ')' }}</span></td>
                                            <td class="px-0 text-right" v-if="isTaxInvoice">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invprice_converted,getTaxCurrencyPrecision(Office__currency_id)) : '' }}</td>
                                            <td class="px-0 text-right width-15-pct" v-if="isTaxInvoice"><span class="ml-2">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invoiceamount_converted,getTaxCurrencyPrecision(Office__currency_id)) : '(' + formatThisNumber(item.Salesitem.invoiceamount_converted,getTaxCurrencyPrecision(Office__currency_id)) + ')' }}</span></td>
                                        </tr>
                                    </template>
                                    <template v-else-if="item.type == 'othercharge' && item.Salesitem.chargeto == 0">
                                        <tr>
                                            <td></td>
                                            <td class="px-0" colspan="3">{{ item.Salesitem.size }}</td>
                                            <td></td>
                                            <td></td>
                                            <td class="px-0 text-right">{{ formatThisNumber(item.Salesitem.invoiceamount,currencyFormatPrecision) }}</td>
                                            <td class="px-0 text-right" v-if="Invoice__show_alternate_currency && [5].includes(Contract__salestype_id) && Contract__currency_id != Invoice__currency_id">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invprice_converted,currencyFormatPrecision) : '' }}</td>
                                            <td class="px-0 text-right width-15-pct" v-if="Invoice__show_alternate_currency && [5].includes(Contract__salestype_id) && Contract__currency_id != Invoice__currency_id">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invoiceamount_converted,currencyFormatPrecision) : '(' + formatThisNumber(item.Salesitem.invoiceamount_converted,currencyFormatPrecision) + ')' }}</td>
                                            <td class="px-0 text-right" v-if="isTaxInvoice">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invprice_converted,getTaxCurrencyPrecision(Office__currency_id)) : '' }}</td>
                                            <td class="px-0 text-right width-15-pct" v-if="isTaxInvoice">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.invoiceamount_converted,getTaxCurrencyPrecision(Office__currency_id)) : '(' + formatThisNumber(item.Salesitem.invoiceamount_converted,getTaxCurrencyPrecision(Office__currency_id)) + ')' }}</td>
                                        </tr>
                                    </template>
                                </template>
                            </v-simple-table>
                        </v-col>
                        <div class="px-0 pb-0 pt-3 ma-0 mt-auto">
                            <v-simple-table class="specification-table" dense>
                                <template v-slot:default>
                                    <tr v-if="(Invoice__remark != null && Invoice__remark != '') || Contract__fsc != 0">
                                        <td :colspan="(Invoice__show_alternate_currency && [5].includes(Contract__salestype_id) && Contract__currency_id != Invoice__currency_id) || (isTaxInvoice == true) ? 9 : 6">
                                            <v-row no-gutters>
                                                <v-col class="d-flex flex-column col-9 justify-end">
                                                    <span class="font-weight-bold py-0 my-0">{{ $t('message.remarks') }}:</span>
                                                    <span style="white-space: pre-line" v-if="Invoice__remark != null">
                                                        {{ Invoice__remark != null ? Invoice__remark.trim() : '' }}
                                                    </span>
                                                </v-col>
                                                <v-col class="d-flex flex-column col-3 align-end">
                                                    <span class="stamp-area" v-if="Customer__country_id == 3 && Contract__office_id == 3">
                                                        <img :src="'/static/img/appic/stamps/stampappltd.png'" height="70px">
                                                    </span>
                                                    <span class="stamp-area pt-2 pl-5" v-if="Customer__country_id == 3 && Contract__office_id == 1">
                                                        <img :src="'/static/img/appic/stamps/stampappsdnbhd.png'" height="80px">
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </tr>
                                    <tr v-else>
                                        <td :colspan="(Invoice__show_alternate_currency && [5].includes(Contract__salestype_id) && Contract__currency_id != Invoice__currency_id) || (isTaxInvoice == true) ? 9 : 6">
                                            <v-row no-gutters>
                                                <v-col class="d-flex flex-column col-12 align-end">
                                                    <span class="stamp-area pt-2 pl-5" v-if="Customer__country_id == 3 && Contract__office_id == 1">
                                                        <img :src="'/static/img/appic/stamps/stampappsdnbhd.png'" height="80px">
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td :colspan="(Invoice__show_alternate_currency && [5].includes(Contract__salestype_id) && Contract__currency_id != Invoice__currency_id) || (isTaxInvoice == true) ? 9 : 6">
                                            <v-row no-gutters>
                                                <v-col class="d-flex flex-column col-9" v-if="[7,8].includes(Contract__fsc) == false && Contract__fsc != null && Contract__fsc != 0">
                                                    <span>{{ $t('message.certCode') + ' : ' + getCertification(Contract__fsc,'cert_no') }}</span>
                                                    <span>{{ $t('message.expiryDate') + ' : ' + getCertification(Contract__fsc,'cert_expiry_date') }}</span>
                                                </v-col>
                                                <v-col class="col-9" v-else></v-col>
                                                <template v-if="Contract__office_id != 20">
                                                    <v-col class="d-flex flex-column col-3 align-end" v-if="Invoice__show_alternate_currency && [5].includes(Contract__salestype_id) &&  Contract__currency_id != Invoice__currency_id ">
                                                        <span>{{ $t('message.exchangeRate') +': '+ formatThisNumber(Invoice__currencyrate,'0.0000') }}</span>
                                                        <span>{{ $t('message.date') + ': ' + formatDate(Invoice__invdate) }}</span>
                                                    </v-col>
                                                    <v-col class="d-flex flex-column col-3 align-end" v-if="isTaxInvoice">
                                                        <span>{{ $t('message.exchangeRate') +': '+ formatThisNumber(Invoice__gstrmrate,'0.0000') }}</span>
                                                        <span>{{ $t('message.date') + ': ' + formatDate(Invoice__invdate) }}</span>
                                                    </v-col>
                                                </template>
                                                <template v-else>
                                                    <v-col class="d-flex flex-column col-3 align-end" v-if="Invoice__show_alternate_currency && [5].includes(Contract__salestype_id) &&  dualCurrency">
                                                        <span>{{ $t('message.exchangeRate') +': '+ formatThisNumber(Invoice__currencyrate,'0.0000') }}</span>
                                                        <span>{{ $t('message.date') + ': ' + formatDate(Invoice__invdate) }}</span>
                                                    </v-col>
                                                    <v-col class="d-flex flex-column col-3 align-end" v-if="isTaxInvoice">
                                                        <span>{{ $t('message.exchangeRate') +': '+ formatThisNumber(Invoice__gstrmrate,'0.0000') }}</span>
                                                        <span>{{ $t('message.date') + ': ' + formatDate(Invoice__invdate) }}</span>
                                                    </v-col>
                                                </template>
                                            </v-row>
                                        </td>
                                    </tr>
                                    <template v-if="!Invoice__show_alternate_currency">
                                        <tr v-if="[1].includes(Contract__destinatione_id) && Contract__office_id == 1">
                                            <td colspan="6" class="text-center">{{ invoicePaymentNote(1) }}</td>
                                        </tr>
                                        <tr v-if="Contract__office_id == 21 && Contract__salestype_id == 5 && false">
                                            <td colspan="6" class="text-center">{{ invoicePaymentNote(4) }}</td>
                                        </tr>
                                        <tr v-if="Contract__office_id == 20 && Contract__salestype_id == 5 && false">
                                            <td colspan="6" class="text-center">{{ invoicePaymentNote(2) }}</td>
                                        </tr>
                                    </template>
                                    <tr>
                                        <td :class="'pa-0 font-weight-bold border-top-1' + ((office.Office.tax == true && [5].includes(Contract__salestype_id) || Deposit__amount > 0) ? ' table-total' : ' border-bottom-1')"></td>
                                        <td :class="'pa-0 font-weight-bold border-top-1' + ((office.Office.tax == true && [5].includes(Contract__salestype_id) || Deposit__amount > 0) ? ' table-total' : ' border-bottom-1')"></td>
                                        <td :class="'pa-0 font-weight-bold border-top-1' + ((office.Office.tax == true && [5].includes(Contract__salestype_id) || Deposit__amount > 0) ? ' table-total' : ' border-bottom-1')"></td>
                                        <td :class="'pa-0 font-weight-bold text-right width-12-pct border-top-1' + ((office.Office.tax == true && [5].includes(Contract__salestype_id) || Deposit__amount > 0) ? ' table-total' : ' border-bottom-1')"></td>
                                        <td :class="'pa-0 font-weight-bold text-right width-10-pct border-top-1' + ((office.Office.tax == true && [5].includes(Contract__salestype_id) || Deposit__amount > 0) ? ' table-total' : ' border-bottom-1')">{{ $t('message.totalDue') }}</td>
                                        <template v-if="Contract__office_id != 20">
                                            <td :class="'pa-0 font-weight-bold text-right width-15-pct border-top-1' + ((office.Office.tax == true && [5].includes(Contract__salestype_id) || Deposit__amount > 0) ? ' table-total' : ' border-bottom-1')">{{ formatThisNumber(Salesitems__itemsTotalAmount,[5].includes(Contract__salestype_id) ? contractCurrencyFormatPrecision : currencyFormatPrecision) }}</td>
                                            <td :class="'pa-0 font-weight-bold text-right width-10-pct border-top-1' + (office.Office.tax == true && [5].includes(Contract__salestype_id) || Deposit__amount > 0 ? ' table-total' : ' border-bottom-1')" v-if="Invoice__show_alternate_currency && [5].includes(Contract__salestype_id) && Contract__currency_id != Invoice__currency_id">{{ }}</td>
                                        </template>
                                        <template v-else>
                                            <template v-if="dualCurrency">
                                                <td :class="'pa-0 font-weight-bold text-right width-15-pct border-top-1' + ((office.Office.tax == true && [5].includes(Contract__salestype_id) || Deposit__amount > 0) ? ' table-total' : ' border-bottom-1')">{{ formatThisNumber(Salesitems__itemsTotalAmount,[5].includes(Contract__salestype_id) ? contractCurrencyFormatPrecision : currencyFormatPrecision) }}</td>
                                                <td :class="'pa-0 font-weight-bold text-right width-10-pct border-top-1' + (office.Office.tax == true && [5].includes(Contract__salestype_id) || Deposit__amount > 0 ? ' table-total' : ' border-bottom-1')" v-if="Invoice__show_alternate_currency && [5].includes(Contract__salestype_id) && Contract__currency_id != Invoice__currency_id">{{ }}</td>
                                            </template>
                                        </template>
                                        <td :class="'pa-0 font-weight-bold text-right width-15-pct border-top-1' + (office.Office.tax == true && [5].includes(Contract__salestype_id) || Deposit__amount > 0 ? ' table-total' : ' border-bottom-1')" v-if="Invoice__show_alternate_currency && [5].includes(Contract__salestype_id) && Contract__currency_id != Invoice__currency_id">{{ formatThisNumber(Salesitems__itemsTotalAmountConverted,currencyFormatPrecision)}}</td>
                                        <td :class="'pa-0 font-weight-bold text-right width-10-pct border-top-1' + (isTaxInvoice ? ' table-total border-bottom-1' : ' border-bottom-1')" v-if="isTaxInvoice && Deposit__amount == 0">{{ }}</td>
                                        <td :class="'pa-0 font-weight-bold text-right width-15-pct border-top-1' + (isTaxInvoice ? ' table-total border-bottom-1' : ' border-bottom-1')" v-if="isTaxInvoice && Deposit__amount == 0">{{ formatThisNumber(Salesitems__itemsTotalAmountConverted,getTaxCurrencyPrecision(Office__currency_id))}}</td>
                                        <td class="pa-0 font-weight-bold text-right width-10-pct border-top-1 table-total" v-if="isTaxInvoice && Deposit__amount > 0">{{ }}</td>
                                        <td class="pa-0 font-weight-bold text-right width-15-pct border-top-1 table-total" v-if="isTaxInvoice && Deposit__amount > 0">{{ formatThisNumber(Salesitems__itemsTotalAmountConverted,getTaxCurrencyPrecision(Office__currency_id))}}</td>
                                    </tr>
                                    <template v-if="Deposit__amount > 0 && [5].includes(Contract__salestype_id) && Contract__office_id == 20">
                                        <tr>
                                            <td class="table-total pa-0"></td>
                                            <td class="table-total pa-0"></td>
                                            <td class="table-total pa-0"></td>
                                            <td class="table-total pa-0"></td>
                                            <td class="table-total pa-0 border-bottom-1 text-right">{{ $t('message.lessDeposit') }}</td>
                                            <template v-if="dualCurrency">
                                                <td class="table-total pa-0 border-bottom-1 width-15-pct text-right">{{ formatThisNumber(Deposit__amount, currencyFormatPrecision) }}</td>
                                                <td class="table-total pa-0 border-bottom-1 width-10-pct text-right" v-if="Invoice__show_alternate_currency">{{ Deposit__exchange_rate != 1 && Deposit__exchange_rate != Invoice__currencyrate ? formatThisNumber(Deposit__exchange_rate,'0,0.0000') : '' }}</td>
                                            </template>
                                            <td class="table-total pa-0 border-bottom-1 width-15-pct text-right" v-if="Invoice__show_alternate_currency">{{ formatThisNumber(Deposit__amount_converted ,  getTaxCurrencyPrecision(Invoice__currency_id))  }}</td>
                                        </tr>
                                        <tr>
                                            <td class="table-total pa-0"></td>
                                            <td class="table-total pa-0"></td>
                                            <td class="table-total pa-0"></td>
                                            <td class="table-total pa-0"></td>
                                            <td class="table-total pa-0 font-weight-bold text-right">{{ $t('message.netDue') }}</td>
                                            <template v-if="dualCurrency">
                                                <td class="table-total pa-0 font-weight-bold text-right">{{ formatThisNumber((Salesitems__itemsTotalAmount - Deposit__amount), currencyFormatPrecision) }}</td>
                                                <td class="table-total pa-0 text-right" v-if="Invoice__show_alternate_currency"></td>
                                            </template>
                                            <td class="table-total pa-0 font-weight-bold text-right" v-if="Invoice__show_alternate_currency">{{ formatThisNumber((Salesitems__itemsTotalAmountConverted - Deposit__amount_converted) ,  getTaxCurrencyPrecision(Invoice__currency_id))  }}</td>
                                        </tr>
                                    </template>
                                    <template v-if="office.Office.tax == true && [5].includes(Contract__salestype_id)">
                                        <tr>
                                            <td class="table-total pa-0"></td>
                                            <td class="table-total pa-0"></td>
                                            <td class="table-total pa-0"></td>
                                            <td class="table-total pa-0"></td>
                                            <td class="table-total pa-0 font-weight-bold text-right">{{ office.Office.taxterm + ' ' + (Invoice__gstpercent != null ? Invoice__gstpercent : office.Office.taxrate) + '%' }}</td>
                                            <template v-if="Contract__office_id != 20">
                                                <td class="table-total pa-0 font-weight-bold text-right">{{ formatThisNumber(Salesitems__itemsTotalTax,[5].includes(Contract__salestype_id) ? contractCurrencyFormatPrecision : currencyFormatPrecision) }}</td>
                                                <td class="table-total pa-0" v-if="Invoice__show_alternate_currency"></td>
                                            </template>
                                            <template v-if="Contract__office_id == 20 && dualCurrency">
                                                <td class="table-total pa-0 font-weight-bold text-right">{{ formatThisNumber(Salesitems__itemsTotalTax,[5].includes(Contract__salestype_id) ? contractCurrencyFormatPrecision : currencyFormatPrecision) }}</td>
                                                <td class="table-total pa-0" v-if="Invoice__show_alternate_currency"></td>
                                            </template>
                                            <td class="table-total pa-0 font-weight-bold text-right" v-if="Invoice__show_alternate_currency">{{ formatThisNumber(Salesitems__itemsTotalTaxConverted,currencyFormatPrecision) }}</td>
                                        </tr>
                                        <tr>
                                            <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                                            <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                                            <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                                            <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                                            <td class="table-total pa-0 font-weight-bold width-10-pct border-bottom-1 text-right">{{ $t('message.grandTotal') }}</td>
                                            <template v-if="Contract__office_id != 20">
                                                <td class="table-total pa-0 font-weight-bold width-15-pct border-bottom-1 text-right">{{ formatThisNumber(Salesitems__itemsTotalAmountWithTax,[5].includes(Contract__salestype_id) ? contractCurrencyFormatPrecision : currencyFormatPrecision) }}</td>
                                                <td class="table-total pa-0 font-weight-bold width-10-pct border-bottom-1" v-if="Invoice__show_alternate_currency"></td>
                                            </template>
                                            <template v-if="Contract__office_id == 20 && dualCurrency">
                                                <td class="table-total pa-0 font-weight-bold width-15-pct border-bottom-1 text-right">{{ formatThisNumber(Salesitems__itemsTotalAmountWithTax,[5].includes(Contract__salestype_id) ? contractCurrencyFormatPrecision : currencyFormatPrecision) }}</td>
                                                <td class="table-total pa-0 font-weight-bold width-10-pct border-bottom-1" v-if="Invoice__show_alternate_currency"></td>
                                            </template>
                                            <td class="table-total pa-0 font-weight-bold width-15-pct border-bottom-1 text-right" v-if="Invoice__show_alternate_currency">{{ formatThisNumber(Salesitems__itemsTotalAmountWithTaxConverted,currencyFormatPrecision) }}</td>
                                        </tr>
                                    </template>
                                    <tr v-if="Deposit__amount > 0 && [5].includes(Contract__salestype_id) == false && Contract__office_id != 1">
                                        <td class="table-total pa-0 border-bottom-1"></td>
                                        <td class="table-total pa-0 border-bottom-1"></td>
                                        <td class="table-total pa-0 border-bottom-1"></td>
                                        <td colspan="2" class="table-total pa-0 border-bottom-1 text-right">{{ $t('message.lessDeposit') }}</td>
                                        <td class="table-total pa-0 width-15-pct border-bottom-1 text-right">{{ formatThisNumber(Deposit__amount, currencyFormatPrecision) }}</td>
                                        <td class="table-total pa-0 width-10-pct border-bottom-1 text-right" v-if="isTaxInvoice">{{ Deposit__exchange_rate != 1 && Invoice__gstrmrate != Deposit__exchange_rate ? formatThisNumber(Deposit__exchange_rate,'0.0000') : '' }}</td>
                                        <td class="table-total pa-0 width-15-pct border-bottom-1 text-right" v-if="isTaxInvoice">{{ formatThisNumber(Deposit__amount * Deposit__exchange_rate , getTaxCurrencyPrecision(Office__currency_id))  }}</td>
                                    </tr>
                                    <tr v-if="Deposit__amount > 0 && [5].includes(Contract__salestype_id) == false && Contract__office_id == 1">
                                        <td class="table-total pa-0 border-bottom-1"></td>
                                        <td class="table-total pa-0 border-bottom-1"></td>
                                        <td class="table-total pa-0 border-bottom-1"></td>
                                        <td colspan="2" class="table-total pa-0 border-bottom-1 text-right">{{ $t('message.lessDeposit') }}</td>
                                        <td class="table-total pa-0 width-15-pct border-bottom-1 text-right">{{ formatThisNumber(Deposit__amount, currencyFormatPrecision) }}</td>
                                        <td class="table-total pa-0 width-10-pct border-bottom-1" v-if="isTaxInvoice">{{ Deposit__exchange_rate != 1 && Invoice__gstrmrate != Deposit__exchange_rate ? formatThisNumber(Deposit__exchange_rate,'0.0000') : '' }}</td>
                                        <td class="table-total pa-0 width-15-pct border-bottom-1 text-right" v-if="isTaxInvoice">{{ formatThisNumber(Deposit__amount * Deposit__exchange_rate , getTaxCurrencyPrecision(Office__currency_id))  }}</td>
                                    </tr>
                                    <tr v-if="Deposit__amount > 0 && [5].includes(Contract__salestype_id) && Contract__office_id == 1">
                                        <td class="table-total pa-0 border-bottom-1"></td>
                                        <td class="table-total pa-0 border-bottom-1"></td>
                                        <td class="table-total pa-0 border-bottom-1"></td>
                                        <td colspan="2" class="table-total pa-0 border-bottom-1 text-right">{{ $t('message.lessDeposit') }}</td>
                                        <td class="table-total pa-0 width-15-pct border-bottom-1 text-right">{{ formatThisNumber(Deposit__amount, currencyFormatPrecision) }}</td>
                                        <td class="table-total pa-0 width-10-pct border-bottom-1 text-right" v-if="Invoice__show_alternate_currency">{{ Deposit__exchange_rate != 1 && Deposit__exchange_rate != Invoice__currencyrate ? formatThisNumber(Deposit__exchange_rate,'0,0.0000') : '' }}</td>
                                        <td class="table-total pa-0 width-15-pct border-bottom-1 text-right" v-if="Invoice__show_alternate_currency">{{ formatThisNumber(Deposit__amount * Deposit__exchange_rate ,  getTaxCurrencyPrecision(Invoice__currency_id))  }}</td>
                                    </tr>
                                    <tr v-if="Deposit__amount > 0 && [5].includes(Contract__salestype_id) == false">
                                        <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                                        <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                                        <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                                        <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                                        <td class="table-total pa-0 font-weight-bold width-10-pct border-bottom-1 text-right">{{ $t('message.grandTotal') }}</td>
                                        <td class="table-total pa-0 font-weight-bold width-12-pct border-bottom-1 text-right">{{ formatThisNumber(Salesitems__itemsTotalAmount - Deposit__amount, currencyFormatPrecision) }}</td>
                                        <td class="table-total pa-0 font-weight-bold border-bottom-1 text-right" v-if="isTaxInvoice">{{ }}</td>
                                        <td class="table-total pa-0 font-weight-bold border-bottom-1 text-right" v-if="isTaxInvoice">{{ formatThisNumber((Salesitems__itemsTotalAmountConverted - (Deposit__amount * Deposit__exchange_rate).toFixed(getTaxCurrencyDecimals(Office__currency_id))), getTaxCurrencyPrecision(Office__currency_id)) }}</td>
                                    </tr>
                                    <tr v-if="Deposit__amount > 0 && [5].includes(Contract__salestype_id) && Contract__office_id == 1">
                                        <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                                        <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                                        <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                                        <td class="table-total pa-0 font-weight-bold border-bottom-1"></td>
                                        <td class="table-total pa-0 font-weight-bold width-10-pct border-bottom-1 text-right">{{ $t('message.grandTotal') }}</td>
                                        <td class="table-total pa-0 font-weight-bold width-12-pct border-bottom-1 text-right">{{ formatThisNumber(Salesitems__itemsTotalAmount - Deposit__amount, currencyFormatPrecision) }}</td>
                                        <td class="table-total pa-0 font-weight-bold border-bottom-1 text-right" v-if="Invoice__show_alternate_currency">{{ }}</td>
                                        <td class="table-total pa-0 font-weight-bold border-bottom-1 text-right" v-if="Invoice__show_alternate_currency">{{ formatThisNumber((Salesitems__itemsTotalAmountConverted - (Deposit__amount * Deposit__exchange_rate)), getTaxCurrencyPrecision(Invoice__currency_id)) }}</td>
                                    </tr>
                                </template>
                            </v-simple-table>
                            <template v-if="Invoice__longform != 1">
                                <v-row no-gutters class="pt-2" v-if="Contract__showbank">
                                    <v-col cols="2" class="font-weight-bold">{{ $t('message.pleaseRemitTo') }}:</v-col>
                                    <v-col cols="10">
                                        <v-row no-gutters v-if="Bank__beneficiary != null && Bank__beneficiary != ''">
                                            <v-col class="col-2-5">{{ $t('message.beneficiaryName') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__beneficiary }}</v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="Bank__account != null && Bank__account != ''">
                                            <v-col class="col-2-5">{{ $t('message.account') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__account }}</v-col>
                                        </v-row>
                                        <template v-if="!Invoice__show_alternate_currency && Contract__destinatione_id == 1">
                                            <v-row no-gutters v-if="Bank__account_original_currency != null && Bank__account_original_currency != ''">
                                                <v-col class="col-2-5">{{ $t('message.account') }}:</v-col>
                                                <v-col class="col-9-5">{{ Bank__account_original_currency }}</v-col>
                                            </v-row>
                                        </template>
                                        <template v-if="!Invoice__show_alternate_currency && [21,20].includes(Contract__office_id)">
                                            <v-row no-gutters v-if="Bank__account_original_currency != null && Bank__account_original_currency != ''">
                                                <v-col class="col-2-5">{{ $t('message.account') }}:</v-col>
                                                <v-col class="col-9-5">{{ Bank__account_original_currency }}</v-col>
                                            </v-row>
                                        </template>
                                        <v-row no-gutters v-if="Bank__title != null && Bank__title != ''">
                                            <v-col class="col-2-5">{{ $t('message.bank') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__title }}</v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="Bank__swiftcode != null && Bank__swiftcode != ''">
                                            <v-col class="col-2-5">{{ $t('message.swiftId') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__swiftcode }}</v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="Bank__address != null && Bank__address != ''">
                                            <v-col class="col-2-5">{{ $t('message.bankAddress') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__address }}</v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="Bank__agent != null && Bank__agent != ''">
                                            <v-col class="col-2-5">{{ $t('message.agentBank') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__agent }}</v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="Bank__agentswiftcode != null && Bank__agentswiftcode != ''">
                                            <v-col class="col-2-5">{{ $t('message.swiftId') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__agentswiftcode }}</v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="pt-2">
                                    <v-col cols="12" class="font-weight-bold">{{ $t('message.importantNotes') + ':' }}</v-col>
                                    <v-col cols="12" v-for="footerNote in invoiceFooterNotes" v-if="Invoice__footer_notes.includes(footerNote.id)" :key="footerNote.id">
                                        <div class="d-flex flex-row align-stretch">
                                            <span class="px-1">{{ footerNote.id + '.' }}</span>
                                            <span class="footer-note text-wrap">{{ parseFooterNote(footerNote) }}</span>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12" class="text-center font-weight-bold">{{ $t('message.documentElectronicallyGenerated') }}</v-col>
                                </v-row>
                            </template>
                        </div>
                    </div>
                </v-app>
            </section>
            <section slot="pdf-content" v-if="Invoice__longform == 1">
                <v-app>
                    <div class="d-flex flex-column fill-height paper-a4">
                        <div class="px-0 pb-0 pt-3 ma-0 mb-auto">
                            <v-row no-gutters class="pt-2" v-if="Contract__showbank">
                                <v-col cols="2" class="font-weight-bold">{{ $t('message.pleaseRemitTo') }}:</v-col>
                                <v-col cols="10">
                                    <v-row no-gutters v-if="Bank__beneficiary != null && Bank__beneficiary != ''">
                                        <v-col class="col-2-5">{{ $t('message.beneficiaryName') }}:</v-col>
                                        <v-col class="col-9-5">{{ Bank__beneficiary }}</v-col>
                                    </v-row>
                                    <v-row no-gutters v-if="Bank__account != null && Bank__account != ''">
                                        <v-col class="col-2-5">{{ $t('message.account') }}:</v-col>
                                        <v-col class="col-9-5">{{ Bank__account }}</v-col>
                                    </v-row>
                                    <template v-if="!Invoice__show_alternate_currency && Contract__destinatione_id == 1">
                                        <v-row no-gutters v-if="Bank__account_original_currency != null && Bank__account_original_currency != ''">
                                            <v-col class="col-2-5">{{ $t('message.account') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__account_original_currency }}</v-col>
                                        </v-row>
                                    </template>
                                    <template v-if="!Invoice__show_alternate_currency && [21,20].includes(Contract__office_id)">
                                        <v-row no-gutters v-if="Bank__account_original_currency != null && Bank__account_original_currency != ''">
                                            <v-col class="col-2-5">{{ $t('message.account') }}:</v-col>
                                            <v-col class="col-9-5">{{ Bank__account_original_currency }}</v-col>
                                        </v-row>
                                    </template>
                                    <v-row no-gutters v-if="Bank__title != null && Bank__title != ''">
                                        <v-col class="col-2-5">{{ $t('message.bank') }}:</v-col>
                                        <v-col class="col-9-5">{{ Bank__title }}</v-col>
                                    </v-row>
                                    <v-row no-gutters v-if="Bank__swiftcode != null && Bank__swiftcode != ''">
                                        <v-col class="col-2-5">{{ $t('message.swiftId') }}:</v-col>
                                        <v-col class="col-9-5">{{ Bank__swiftcode }}</v-col>
                                    </v-row>
                                    <v-row no-gutters v-if="Bank__address != null && Bank__address != ''">
                                        <v-col class="col-2-5">{{ $t('message.bankAddress') }}:</v-col>
                                        <v-col class="col-9-5">{{ Bank__address + (Bank__country != null ? ', ' + Bank__country : '') }}</v-col>
                                    </v-row>
                                    <v-row no-gutters v-if="Bank__agent != null && Bank__agent != ''">
                                        <v-col class="col-2-5">{{ $t('message.agentBank') }}:</v-col>
                                        <v-col class="col-9-5">{{ Bank__agent }}</v-col>
                                    </v-row>
                                    <v-row no-gutters v-if="Bank__agentswiftcode != null && Bank__agentswiftcode != ''">
                                        <v-col class="col-2-5">{{ $t('message.swiftId') }}:</v-col>
                                        <v-col class="col-9-5">{{ Bank__agentswiftcode }}</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="pt-2">
                                <v-col cols="12" class="font-weight-bold">{{ $t('message.importantNotes') + ':' }}</v-col>
                                <v-col cols="12" v-for="footerNote in invoiceFooterNotes" v-if="Invoice__footer_notes.includes(footerNote.id)" :key="footerNote.id">
                                    <div class="d-flex flex-row align-stretch">
                                        <span class="px-1">{{ footerNote.id + '.' }}</span>
                                        <span class="footer-note text-wrap">{{ parseFooterNote(footerNote) }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" class="text-center font-weight-bold">{{ $t('message.documentElectronicallyGenerated') }}</v-col>
                            </v-row>
                        </div>
                    </div>
                </v-app>
            </section>
        </vue-html2pdf>
    </div>
</template>

<script>
    import AppConfig from "Constants/AppConfig";
    // import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    // import {mapActions, mapGetters} from "vuex";
    import {formatDate, isNumeric, numberFormat, nl2br, log} from "Helpers/helpers";
    import {api} from "Api";
    import {
        buyerCaptionTypes,
        // certifications,
        // certificationsNew,
        containerSizes,
        // currencies,
        deliveryAddressTypes,
        incoterms,
        invoiceFooterNotes,
        offices,
        uofm
    } from "@/store/modules/appic/constants";
    import VueHtml2pdf from 'vue-html2pdf';

    export default {
        name: "PrintInvoiceBlobV3",
        props: ['invoiceId','isTaxInvoice','dualCurrency'],
        components: {VueHtml2pdf},
        data() {
            return {
                appLogo: AppConfig.appSessionLogo,
                deliveryAddresses: [],
                loaded: false,
                buyerCaptionTypes: buyerCaptionTypes,
                certificationsNew: [],
                containerSizes: containerSizes,
                currencies: [],
                deliveryAddressTypes: deliveryAddressTypes,
                htmlToPdfOptions: {
                    image: {
                        type: 'jpeg',
                        quality: 0.70
                    },
                    html2canvas: {
                        scale: 1.5
                    }
                },
                incoterms: incoterms,
                invoiceFooterNotes: invoiceFooterNotes,
                lcTerms: [],
                offices: offices,
                uofmQty: uofm.qty,
                useLcDescriptions: false,
                Bank__address: null,
                Bank__agent: null,
                Bank__agentswiftcode: null,
                Bank__country: null,
                Bank__id: null,
                Bank__title: null,
                Bank__account: null,
                Bank__account_original_currency: null,
                Bank__swiftcode: null,
                Bank__beneficiary: null,
                Contact__email: null,
                Contact__hp: null,
                Contact__name: null,
                Contact__prefix: null,
                Contact__tel: null,
                Contract__containercount: null,
                Contract__containersize_id: 1,
                Contract__currency_id: null,
                Contract__destinatione_id: null,
                Contract__fsc: 0,
                Contract__id: null,
                Contract__incoterm_id: null,
                Contract__incotermport: null,
                Contract__is_export: 0,
                Contract__loadingport_id: null,
                Contract__office_id: 1,
                Contract__partial_no: null,
                Contract__paymentstatement_id: null,
                Contract__note: null,
                Contract__revision_no: null,
                Contract__salestype_id: 1,
                Contract__shippingport_id: null,
                Contract__showbank: null,
                Contract__title: null,
                Contract__version: null,
                Customer__title: null,
                Customer__address1: null,
                Customer__address2: null,
                Customer__address3: null,
                Customer__city: null,
                Customer__country: null,
                Customer__country_id: null,
                Customer__gstno: null,
                Customer__postcode: null,
                Customer__state: null,
                Customer__taxno_type: null,
                Customer__uscc: null,
                Deposit__amount: 0,
                Deposit__amount_converted: 0,
                Deposit__exchange_rate: 1,
                Invoice__currency_id: 2,
                Invoice__footer_notes: [],
                Invoice__hide_conversion: null,
                Invoice__longform: 0,
                Invoice__gstpercent: 0,
                Invoice__gstrmrate: 1,
                Invoice__invno: null,
                Invoice__invdate: null,
                Invoice__remark: null,
                Invoice__show_alternate_currency: true,
                Loadingport__title: null,
                Office__title: null,
                Office__address1: null,
                Office__address2: null,
                Office__city: null,
                Office__companyno: null,
                Office__contracttel: null,
                Office__country: null,
                Office__currency_id: 1,
                Office__gstno: null,
                Office__invoiceemail: null,
                Office__invoicetel: null,
                Office__postcode: null,
                Office__state: null,
                Office__stamp: null,
                Office__stamp_ws: null,
                Office__taxno_type: null,
                Office__website: null,
                Origin__name: null,
                Paymentstatement__title: null,
                Shippingport__title: null,
                Salescontact__name: null,
                Salesitems: [],
                Salesitems__itemsTotalAmount: 0,
                Salesitems__itemsTotalAmountConverted: 0,
                Salesitems__itemsTotalQty: [],
                Salesitems__itemsTotalTax: 0,
                Salesitems__itemsTotalTaxConverted: 0,
                Salesitems__itemsTotalAmountWithTax: 0,
                Salesitems__itemsTotalAmountWithTaxConverted: 0,
            };
        },
        computed: {
            buyerCaption() {
                return this.buyerCaptionTypes.find((caption)=>caption.id == this.Contract__buyeraddresstype)?.title_first
            },
            contractIncoterm() {
                let incoterm = this.incoterms.find((incoterm)=>incoterm.Incoterm.id == this.Contract__incoterm_id)?.Incoterm?.title;
                let incotermport = null;
                switch(this.Contract__incotermport){
                    case 0: //POD
                        if(this.Contract__shippingport_id != null) {
                            incotermport = ' ' + this.Shippingport__title
                        }
                        break;
                    case 1: //POL
                        if(this.Contract__loadingport_id != null) {
                            incotermport = ' ' + this.Loadingport__title
                        }
                        break;
                    case 2:
                        incotermport = ''
                        break
                }
                return incoterm + incotermport;
            },
            contractCurrencyFormatPrecision () {
                return this.currencies.find((currency) => currency?.Currency?.id === this.Contract__currency_id)?.Currency?.formatPrecision
            },
            currencyFormatPrecision () {
                return this.currencies.find((currency) => currency?.Currency?.id === this.Invoice__currency_id)?.Currency?.formatPrecision
            },
            deliveryCaption() {
                return this.buyerCaptionTypes.find((caption)=>caption.id == this.Contract__buyeraddresstype)?.title_last
            },
            documentTitle() {
                let title = this.$t('message.commercialInvoice')
                if(this.Contract__office_id == 20) title = this.$t('message.taxInvoice')
                return title
            },
            footerNoteReplacements (){
                let obj = {}
                if([1,3].includes(this.Contract__office_id)){
                    obj.interest_charge_pct = '1.5%'
                } else {
                    obj.interest_charge_pct = '2%'
                }
                return obj
            },
            office (){
                let office = this.offices.find(o => o.Office.id == this.Contract__office_id)
                if(office == null){
                    office = this.offices.find(o => o.Office.id == 3)
                }
                return office
            }
        },
        methods: {
            async beforeDownload ({ html2pdf, options, pdfContent }) {
                await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                    let reader = new FileReader()
                    let _this = this
                    reader.readAsDataURL(pdf.output('blob'));
                    reader.onloadend = function () {
                        let file = reader.result;
                        let url = '/invoices/pdf'
                        if(_this.dualCurrency){
                            url = '/invoices/dual-currency/pdf'
                        }
                        api
                            .post('/invoices/pdf',{
                                pdf: {
                                    contract_no: [_this.Contract__title, _this.Contract__revision_no , _this.Contract__partial_no].filter(Boolean).join('-'),
                                    invoice_no: _this.Invoice__invno,
                                    invoice_id: _this.invoiceId,
                                    file: file
                                },
                            })
                            .then((response) => {
                                if(response.data.status == 'success') {
                                    // _this.$emit('pdf-uploaded', response.data.url)
                                    // test only
                                    _this.$emit('pdf-uploaded', response.data.url)
                                } else {
                                    _this.$emit('pdf-not-uploaded')
                                }
                            })
                            .catch(() => {
                                _this.$emit('pdf-not-uploaded')
                            })
                    }
                })
            },
            calculateOtherValuesOnItems () {
                return new Promise((resolve, reject) => {
                    this.Salesitems.forEach((item, index) => {
                        if (this.Contract__salestype_id == 5 && this.Contract__office_id == 20) {
                            this.Salesitems[index]['Salesitem']['invPriceConvertedInCbf'] = this.Salesitems[index]['Salesitem']['invoiceamount_converted'].toFixed(2) / this.Salesitems[index]['Salesitem']['invQtyInCbf'].toFixed(3)
                        }
                    })
                    resolve('done')
                })
            },
            calculateTaxOnItems () {
                return new Promise((resolve, reject) => {
                    this.Salesitems.forEach((item, index) => {
                        this.Salesitems[index]['Salesitem']['invprice_converted'] = parseFloat(this.Salesitems[index]['Salesitem']['invprice']) * this.Invoice__gstrmrate
                        let decimals = this.getTaxCurrencyDecimals(this.Office__currency_id)
                        let invPriceConverted = this.Salesitems[index]['Salesitem']['invprice_converted'].toFixed(decimals);
                        this.Salesitems[index]['Salesitem']['invoiceamount_converted'] = parseFloat(this.Salesitems[index]['Salesitem']['invqty']) * parseFloat(invPriceConverted)

                        if(this.Contract__salestype_id == 5 && this.Contract__office_id == 20){
                            this.Salesitems[index]['Salesitem']['invPriceConvertedInCbf'] = this.Salesitems[index]['Salesitem']['invoiceamount_converted'].toFixed(2) / this.Salesitems[index]['Salesitem']['invQtyInCbf'].toFixed(3)
                        }
                    })
                    resolve('done')
                })
            },
            formatDate,
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            getCertifications() {
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/certifications",{
                        })
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            getCertification(certificationId, certificationInfo) {
                let certificationObj = this.certificationsNew.find(c => c.Certification.id == certificationId)
                if(certificationObj){
                    return certificationObj?.Certification[certificationInfo]
                }
                return ''
            },
            getCertificationLabel(salesDescription, certificationId){
                if(certificationId != null && certificationId != 0){
                    let certificationText = this.certificationsNew.find(c => c.Certification.id == certificationId)?.Certification?.title

                    let searchStrings =  ['FSC','PEFC','SVLK',certificationText];
                    let stringFound = false

                    searchStrings.forEach(string => {
                        if(salesDescription.indexOf(string) > 0) stringFound = true
                    })

                    if(stringFound){
                        return ''
                    } else {
                        return ', ' + certificationText
                    }

                } else {
                    return ''
                }
            },
            getCurrencyCode(currencyId) {
                let code = 'USD'
                const currency = this.currencies.find((currency) => currency.Currency.id == currencyId)
                if(currency) code = currency.Currency.code
                return code
            },
            getCurrencies() {
                return new Promise((resolve, reject) => {
                    log('get currencies')
                    api
                        .get("/print/currencies",{
                        })
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            getLcBasedPaymentTerms() {
                return new Promise((resolve, reject) => {
                    log('get lc based payment terms')
                    api
                        .get("/print/paymentterms/lc/ids",{
                        })
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            getOfficeTaxId () {
                let taxId = ''
                if(this.Office__gstno != null && this.Office__taxno_type != null) taxId = this.Office__taxno_type + ': ' + this.Office__gstno
                if(this.Office__gstno != null && this.Office__taxno_type == null) taxId = this.Office__gstno
                return taxId
            },
            getProduct(itemProduct, itemDescription, itemSpec){
                if(itemDescription != null && itemDescription != ''){
                    if(itemDescription.includes(itemProduct) == false){
                        return (itemProduct != null ? itemProduct + ', ' : '')
                    } else {
                        return ''
                    }
                } else {
                    if(itemSpec != null && itemSpec != '') {
                        if (itemSpec.includes(itemProduct) == false) {
                            return (itemProduct != null ? itemProduct + ', ' : '')
                        } else {
                            return ''
                        }
                    }
                    return itemProduct != null ? itemProduct : ''
                }
            },
            getTaxCurrency (val) {
                let currency = this.currencies.find((currency) => currency.Currency.id == val)
                if(currency.Currency.code == 'MYR'){
                    return 'RM'
                } else {
                    return currency.Currency.code
                }
            },
            getTaxCurrencyPrecision (val) {
                let currency = this.currencies.find((currency) => currency.Currency.id == val)
                return currency.Currency.formatPrecision
            },
            getTaxCurrencyDecimals (val) {
                let currency = this.currencies.find((currency) => currency.Currency.id == val)
                return currency.Currency.decimals
            },
            getTaxId () {
                let taxId = ''
                if(this.Customer__gstno != null && this.Customer__taxno_type != null) taxId = this.Customer__taxno_type + ': ' + this.Customer__gstno
                if(this.Customer__gstno != null && this.Customer__taxno_type == null) taxId = this.Customer__gstno
                return taxId
            },
            getUofmQty(unitId) {
                const uofm = this.uofmQty.find((uofm)=>uofm.Measurement.id == unitId)
                if(uofm) return uofm.Measurement.title
                return ''
            },
            invoicePaymentNote(destinationId) {
                let note = ''
                switch(destinationId) {
                    case 1:
                        note = 'Payment can be made in MYR based on Bank Negara Malaysia prevailing exchange rate on the date of payment.'
                        break
                    case 2:
                        note = 'Payment can be made in THB based on Bank of Thailand (BOT) prevailing exchange rate on the date of payment.'
                        break
                    case 4:
                        note = 'Payment can be made in IDR based on Bank Indonesia (BI) prevailing exchange rate on the date of payment.'
                        break
                }
                return note
            },
            loadInvoiceById (invoice_id) {
                let self = this
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/invoices/" + invoice_id,{
                        })
                        .then(response => {
                            for(let key in response.data.data[0]){
                                if(key == 'Contract__fsc' && response.data.data[0][key] == null){
                                    response.data.data[0]['Contract__fsc'] = 0
                                }
                                self[key] = response.data.data[0][key]
                            }
                            resolve(invoice_id)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            loadSalesItemsByContract (contract_id) {
                let self = this
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/contracts/" + contract_id + '/salesitems',{
                        })
                        .then(response => {
                            let salesItems = response.data.data
                            salesItems.forEach((item, index) => {
                                salesItems[index]['Salesitem']['invQtyInCbf'] = 0
                                salesItems[index]['Salesitem']['invPriceInCbf'] = 0
                                salesItems[index]['Salesitem']['invPriceConvertedInCbf'] = 0
                                //add conditions for STHxxxxx contracts
                                if(this.Contract__salestype_id == 5 && this.Contract__office_id == 20){
                                    //convert quantities and unit prices to CBF
                                    let conversionFactor = 1
                                    switch(salesItems[index]['Salesitem']['measurement_id']){
                                        case 1: //M3
                                            conversionFactor = 35.31467
                                            break;
                                        case 2: //MBF
                                            conversionFactor = 83.33333333
                                            break;
                                    }
                                    salesItems[index]['Salesitem']['invQtyInCbf'] = salesItems[index]['Salesitem']['invqty'] * conversionFactor
                                    salesItems[index]['Salesitem']['invPriceInCbf'] = salesItems[index]['Salesitem']['invoiceamount'].toFixed(2) / salesItems[index]['Salesitem']['invQtyInCbf'].toFixed(3)
                                }
                            })
                            self.Salesitems = salesItems
                            resolve(contract_id)
                        })
                        .catch(error => {
                            reject(error);
                        });
                })
            },
            nl2br,
            parseFooterNote (footerNote) {
                if(footerNote.replace){
                    if(this.footerNoteReplacements[footerNote.replace] != null){
                        return footerNote.text.replace('<<' + footerNote.replace + '>>',this.footerNoteReplacements[footerNote.replace])
                    }
                } else {
                    return footerNote.text
                }
            },
            async updateTotals () {
                let amount = 0, amount_converted = 0, quantities = []
                await this.Salesitems.forEach((item) => {
                    if(item.type == 'credit') {
                        amount -= isNumeric(item.Salesitem.invoiceamount) ? parseFloat(item.Salesitem.invoiceamount) : 0;
                        amount_converted -= isNumeric(item.Salesitem.invoiceamount_converted) ? parseFloat(item.Salesitem.invoiceamount_converted) : 0;
                    } else {
                        amount += isNumeric(item.Salesitem.invoiceamount) ? parseFloat(item.Salesitem.invoiceamount) : 0;
                        amount_converted += isNumeric(item.Salesitem.invoiceamount_converted) ? parseFloat(item.Salesitem.invoiceamount_converted) : 0;
                    }

                    let idx = quantities.findIndex(t=>t.Total.uofm == item.Measurement.title);
                    if(idx !== -1){
                        quantities[idx]['Total']['qty'] += isNumeric(item.Salesitem.invqty) ? parseFloat(item.Salesitem.invqty) : 0;
                    } else {
                        if(item.type == 'default') {
                            let obj = {'Total': {}}
                            obj['Total']['uofm'] = item.Measurement.title;
                            obj['Total']['qty'] = isNumeric(item.Salesitem.invqty) ? parseFloat(item.Salesitem.invqty) : 0;
                            quantities.push(obj)
                        }
                    }
                })
                this.Salesitems__itemsTotalAmount = amount
                this.Salesitems__itemsTotalAmountConverted = amount_converted
                this.Salesitems__itemsTotalQty = quantities

                if(this.office.Office.tax == true){
                    let taxRate = this.office.Office.taxrate / 100
                    if(this.Invoice__gstpercent != null){
                        taxRate = this.Invoice__gstpercent / 100
                    }

                    if(this.Contract__salestype_id == 5 && this.Contract__office_id == 20){
                        //special case for SS TH
                        //calculate using THB and then convert back to original contract currency
                        this.Deposit__amount_converted = this.Deposit__amount * this.Deposit__exchange_rate
                        this.Salesitems__itemsTotalTaxConverted = (this.Salesitems__itemsTotalAmountConverted - this.Deposit__amount_converted) * taxRate
                        if(this.Invoice__currencyrate > 0) {
                            this.Salesitems__itemsTotalTax = this.Salesitems__itemsTotalTaxConverted / this.Invoice__currencyrate
                        } else {
                            this.Salesitems__itemsTotalTax = this.Salesitems__itemsTotalTaxConverted
                        }
                        this.Salesitems__itemsTotalAmountWithTaxConverted = (this.Salesitems__itemsTotalAmountConverted - this.Deposit__amount_converted) + this.Salesitems__itemsTotalTaxConverted
                        if(this.Invoice__currencyrate > 0) {
                            this.Salesitems__itemsTotalAmountWithTax = this.Salesitems__itemsTotalAmountWithTaxConverted / this.Invoice__currencyrate
                        } else {
                            this.Salesitems__itemsTotalAmountWithTax = this.Salesitems__itemsTotalAmountWithTaxConverted
                        }
                        // this.Salesitems__itemsTotalTax = (amount - this.Deposit__amount) * taxRate
                        // this.Salesitems__itemsTotalTaxConverted = (amount - this.Deposit__amount) * taxRate * this.Invoice__currencyrate
                        // this.Salesitems__itemsTotalAmountWithTax = (this.Salesitems__itemsTotalAmount - this.Deposit__amount) + this.Salesitems__itemsTotalTax
                        // let netDue = (this.Salesitems__itemsTotalAmount - this.Deposit__amount) * this.Deposit__exchange_rate
                        // netDue = Math.round((netDue + Number.EPSILON) * 100) / 100
                        // this.Salesitems__itemsTotalAmountWithTaxConverted = netDue + this.Salesitems__itemsTotalTaxConverted
                    } else {
                        this.Salesitems__itemsTotalTax = amount * taxRate
                        this.Salesitems__itemsTotalTaxConverted = amount * taxRate * this.Invoice__currencyrate
                        this.Salesitems__itemsTotalAmountWithTax = this.Salesitems__itemsTotalAmount + this.Salesitems__itemsTotalTax
                        this.Salesitems__itemsTotalAmountWithTaxConverted = this.Salesitems__itemsTotalAmountConverted + this.Salesitems__itemsTotalTaxConverted
                    }
                }
            },
            uofmPrecision (uofm) {
                //Add bypass for Export Sales where M3 has 4 decimal digits
                if(this.Contract__is_export == 1){
                    if(uofm.trim() == 'M3'){
                        return '0,0.000'
                    } else {
                        return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
                    }
                } else {
                    return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
                }
            },
        },
        created() {
            this.getLcBasedPaymentTerms()
                .then((lcPaymentTerms) => {
                    this.lcTerms = lcPaymentTerms
                })
                .catch()
            this.getCurrencies()
                .then((currencies) => {
                    this.currencies = currencies
                })
                .catch()
            this.getCertifications()
                .then((certifications) => {
                    this.certificationsNew = certifications
                })
                .catch()
            if(this.isTaxInvoice){
                this.$emit('pdf-uploading')
                this.loadInvoiceById(this.invoiceId)
                    .then(()=>{
                        if(this.lcTerms.includes(this.Contract__paymentstatement_id)){
                            this.useLcDescriptions = true
                        }
                        this.loadSalesItemsByContract(this.Contract__id)
                            .then(()=>{
                                this.calculateTaxOnItems()
                                    .then(()=>{
                                        this.updateTotals()
                                            .then(()=>{
                                                this.loaded = true
                                                this.$refs.html2Pdf.generatePdf()
                                            })
                                            .catch()
                                    })
                                    .catch()
                            })
                            .catch()
                    })
                    .catch()
            } else {
                this.$emit('pdf-uploading')
                this.loadInvoiceById(this.invoiceId)
                    .then(()=>{
                        if(this.lcTerms.includes(this.Contract__paymentstatement_id)){
                            this.useLcDescriptions = true
                        }
                        this.loadSalesItemsByContract(this.Contract__id)
                            .then(()=>{
                                if(this.Contract__salestype_id == 5 && this.Contract__office_id == 1){
                                    this.Invoice__currency_id = 1
                                    this.Invoice__currencyrate = this.Invoice__gstrmrate
                                    this.calculateTaxOnItems()
                                        .then(()=> {
                                            this.updateTotals().then(() => {
                                                this.loaded = true
                                                this.$refs.html2Pdf.generatePdf()
                                            })
                                        })
                                        .catch()
                                } else {
                                    this.calculateOtherValuesOnItems()
                                        .then(() => {
                                            this.updateTotals()
                                                .then(() => {
                                                    this.loaded = true
                                                    this.$refs.html2Pdf.generatePdf()
                                                })
                                                .catch(() => {
                                                    this.loaded = true
                                                })
                                        })
                                        .catch(() => {
                                            this.loaded = true
                                        })
                                }
                            })
                            .catch(() => {
                                this.loaded = true
                            })
                    })
            }
        }
    }
</script>

<style scoped>
    @font-face {
        font-family: 'AppicArial';
        src: url('/static/fonts/Arial.woff2') format('woff2'),
        url('/static/fonts/Arial.woff2') format('woff'),
        url('/static/fonts/Arial.woff2') format('truetype');
    }
    .border-top-1 {
        border-top: 1px solid black !important;
    }
    .border-bottom-1 {
        border-bottom: 1px solid black !important;
    }
    .border-top-2 {
        border-top: 2px solid black;
    }
    .border-bottom-2 {
        border-bottom: 2px solid black !important;
    }
    .col-2-5 {
        flex: 0 0 20.83333333%;
        max-width: 20.83333333%;
    }
    .col-3-5 {
        flex: 0 0 29.16666667%;
        max-width: 29.16666667%;
    }
    .col-4-5 {
        flex: 0 0 37.5%;
        max-width: 37.5%;
    }
    .col-9-5 {
        flex: 0 0 79.16666667%;
        max-width: 79.16666667%;
    }
    .document-caption {
        font-size: 15px !important;
        line-height: 17px;
    }
    .footer-note {
        font-size: 8pt !important;
    }
    .header-title {
        font-size: 19pt !important;
        line-height: 24px;
        font-weight: bold !important;
    }
    .header-subtitle {
        font-size: 10pt !important;
    }
    .paper-a4 {
        height: 1065px;
        margin-left: 75px;
        margin-top: 35px;
        background-color: #FFFFFF !important;
        color: #000000 !important;
    }
    .specification-table table tr td {
        font-family: 'AppicArial' !important;
        line-height:14px !important;
        font-size:9pt !important;
        padding:2px !important;
    }
    .specification-table table tr.table-header td {
        font-family: 'AppicArial' !important;
        line-height:14px !important;
        font-size:9pt !important;
        padding:0px !important;
        height: 14px !important;
        vertical-align: top !important;
    }
    .specification-table table tr.table-row-main td {
        font-family: 'AppicArial' !important;
        line-height:17px !important;
        font-size:9pt !important;
        padding:0px !important;
        height: 17px !important;
    }
    .specification-table table tr.table-row-sub td {
        font-family: 'AppicArial' !important;
        line-height:13px !important;
        font-size:9pt !important;
        padding:0px !important;
        height: 13px !important;
    }
    .specification-table table tr td.table-total {
        height: 8px !important;
    }
    .stamp-area {
        height: 100px;
    }
    .v-application {
        font-family: 'AppicArial' !important;
        line-height:14px !important;
        font-size:9pt !important;
        padding:2px !important;
        background-color: #FFFFFF !important;
    }
    .width-10-pct {
        width: 12% !important;
    }
    .width-12-pct {
        width: 12% !important;
    }
    .width-18-pct {
        width: 18% !important;
    }
    .width-25-pct {
        width: 25% !important;
    }
    body {
        margin: 0 !important;
    }
    * {
        text-rendering: geometricprecision !important;
    }
</style>